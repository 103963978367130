<template>
  <div style="padding: 15px">
    <!-- 头部 -->
    <el-row style="padding-bottom: 10px">
      <el-col :span="24">
        <el-page-header @back="goSelect" content="历史记录"> </el-page-header>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="select">
      <el-col :span="6" style="padding-right: 0px">
        <el-select v-model="typeName" clearable placeholder="请选择设备类型">
          <el-option
            v-for="item in deviceType"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option> </el-select
      ></el-col>

      <el-col :span="6" style="padding-right: 0px">
        <el-input v-model="deviceId" placeholder="请输入设备号"></el-input>
      </el-col>

      <el-col :span="6" style="padding-right: 0px">
        <el-input v-model="valueName" placeholder="请输入设备属性"></el-input>
      </el-col>

      <el-col :span="6">
        <el-button
          icon="el-icon-search"
          @click="getAllByHandleStatus"
          type="primary"
          >查询</el-button
        ></el-col
      >

      <!-- 导出按钮 -->
      <el-button type="success" @click="export2Excel1">导出历史记录</el-button>
    </el-row>

    <!-- 表格 -->
    <el-table
      style="width: 100%"
      :data="deviceHistoryValues"
      :header-cell-style="{ textAlign: 'center', background: '#eef1f6' }"
      :cell-style="{ textAlign: 'center' }"
      :stripe="true"
      :border="true"
      :show-overflow-tooltip="true"
    >
      <el-table-column
        fixed
        type="index"
        :index="indexMethod"
        label="序号"
        width="70"
      >
      </el-table-column>
      <el-table-column prop="deviceId" label="设备号" width="300">
      </el-table-column>
      <el-table-column prop="typeName" label="设备类型" width="280">
      </el-table-column>
      <el-table-column prop="valueName" label="设备属性" width="280">
      </el-table-column>
      <el-table-column prop="value" label="值" width="280"> </el-table-column>
      <el-table-column prop="createTime" label="创建时间"> </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="5"
      layout="total, prev, pager, next , jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { export_json_to_excel } from "../../introduce/Export2Excel";

import DeviceHistoryValue from "../../api/manageApi/DeviceHistoryValue";
import DeviceTypeManagement from "../../api/manageApi/DeviceTypeManagement";
export default {
  data() {
    return {
      deviceHistoryValues1: [],
      deviceHistoryValues: [], //实时值历史表
      deviceType: [], // 查询设备类型下拉框数据
      typeName: "", //设备类型
      deviceId: "", //设备号
      valueName: "", //设备属性
      total: 0, //总页数
      currentPage: 1, //第几页
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    goSelect() {
      this.$router.push("/Select");
    },
    init() {
      //获取所有历史表中的数据(分页)
      DeviceHistoryValue.getAll(
        this.currentPage,
        this.typeName,
        this.deviceId,
        this.valueName
      ).then((resp) => {
        if (resp) {
          this.deviceHistoryValues = resp.data.data.list;
          // for(let i = 0; i< this.deviceHistoryValues.length;i++){
          //   if(this.deviceHistoryValues[i].valueName == "CH2-检测值"){
          //     this.deviceHistoryValues[i].valueName = "CH2-温度值"
          //   }
          //   if(this.deviceHistoryValues[i].valueName == "CH3-检测值"){
          //     this.deviceHistoryValues[i].valueName = "CH3-温度值"
          //   }
          //   if(this.deviceHistoryValues[i].valueName == "CH4-检测值"){
          //     this.deviceHistoryValues[i].valueName = "CH4-温度值"
          //   }
          //   if(this.deviceHistoryValues[i].valueName == "CH5-检测值"){
          //     this.deviceHistoryValues[i].valueName = "CH5-温度值"
          //   }
          //   if(this.deviceHistoryValues[i].valueName == "CH1-检测值"){
          //     this.deviceHistoryValues[i].valueName = "CH1-漏电流"
          //   }
          // }
          this.total = resp.data.data.total;
        }
      });
      //获取所有设备类型
      DeviceTypeManagement.getAllDeviceType().then((resp) => {
        if (resp) {
          this.deviceType = resp.data.data.list;
        }
      });
    },

    indexMethod(index) {
      // 序号递增
      const currentPage = this.currentPage; // 当前页码
      const pageSize = 5; // 每页条数
      return index + 1 + (currentPage - 1) * pageSize; // 返回表格序号
    },
    handleCurrentChange(val) {
      // 第几页
      this.currentPage = val;
      this.init();
    },

    getAllByHandleStatus() {
      //条件查询
      this.init();
    },

    //导出excel
    export2Excel1() {
      require.ensure([], () => {
        const tHeader = ["设备号", "设备类型", "设备属性", "值", "创建时间"]; // 对应表格输出的中文title

        const filterVal = [
          "deviceId",
          "typeName",
          "valueName",
          "value",
          "createTime",
        ]; // 对应表格输出的数据

        const list = this.deviceHistoryValues1; // 表格data

        const data = this.formatJson(filterVal, list);

        export_json_to_excel(tHeader, data, "历史记录"); // 对应下载文件的名字
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
  },
};
</script>
<style scoped lang="scss">
.el-col-6 {
  width: auto;
}
.inPutStyle {
  width: 180px;
}
.select {
  margin-bottom: 10px;
}
</style>